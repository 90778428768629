<template>
  <a-card title="短信发送记录" :bordered="false">
    <div class="card-content">
      <div class="record" v-for="item in userSmsList" :key="item.id">
        <div class="item-wrap">
          <a-tag class="tag" color="processing">发送</a-tag>
          <div class="item">
            <label class="title">短信模板</label>
            <a-tooltip placement="top" :mouseEnterDelay="0.5">
              <template #title>{{`【${item.smsTemplate.signature}】${item.smsTemplate.content}`}}</template>
              <span class="content">{{ item.smsTemplate.name }}</span>
            </a-tooltip>
          </div>
          <div class="item">
<!--            <label class="title">发送时间</label>-->
            <span class="content">{{ item.sendTime }}</span>
          </div>
        </div>
        <div class="item-wrap">
          <a-tag class="tag" :color="item.replyState === 20 ? 'success' : 'default'">回复</a-tag>
          <div class="item">
            <label class="title">回复内容</label>
            <span class="content">{{ item.replyInfo }}</span>
          </div>
          <div class="item">
<!--            <label class="title">回复时间</label>-->
            <span class="content">{{ item.replyTime }}</span>
          </div>
        </div>
        <a-divider/>
      </div>
      <ak-empty v-if="!userSmsList || userSmsList.length === 0" />
    </div>
  </a-card>
</template>

<script>
import {defineComponent, toRefs, watch} from "vue";
import AkEmpty from "@/components/akEmpty";

export default defineComponent({
  name: "smsCard",
  components: {
    AkEmpty,
  },
  props: {
    userSmsList: {
      type: Object,
    }
  },
  setup(props) {
    const {userSmsList} = toRefs(props);

    watch(userSmsList, () => {
    })
  }
})
</script>

<style lang="less" scoped>
.card-content {
  display: flex;
  flex-direction: column;
}

.item-wrap {
  display: flex;
  width: 100%;
  margin-bottom: 8px;

  .tag {
    margin-right: 12px;
    height: 22px;
  }

  .item {
    display: flex;
    width: 48%;

    .title {
      width: 64px;
      color: #757575;

      &:after {
        content: ':';
      }
    }
  }
}
</style>