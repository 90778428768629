<template>
  <Empty :image="simpleImage" />
</template>

<script>
import {defineComponent} from "vue";
import { Empty } from 'ant-design-vue';

export default defineComponent({
  name: "akEmpty",
  components: {
    Empty,
  },
  setup() {
    return {
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
    };
  },
})
</script>

<style scoped>

</style>