import {ref, computed} from 'vue';

const usePagination = (
    pageNumberKey = 'pageNumber',
    pageSizeKey = 'pageSize',
) => {
    // 定义默认值
    const pageNumber = ref(1);
    const pageSize = ref(10);
    const total = ref(0);
    const data = ref();

    // 调用函数
    const run = (param) => {
        const {queryFunc, ...rest} = param;
        rest[pageNumberKey]--
        queryFunc(rest)
            .then((res) => {
                data.value = res.data;
                total.value = res.total;
                pageNumber.value = rest[pageNumberKey] + 1;
                pageSize.value = rest[pageSizeKey];
            })
    }

    // table 组件的分页参数
    const pagination = computed(() => {
        return {
            total: total.value,
            current: pageNumber.value,
            pageSize: pageSize.value,
            showTotal: (t, r) => (r ? `第 ${r[0]}-${r[1]} 条, ` : '') + `共 ${t} 条`,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '50', '100', '500'],
        }
    });

    return {
        data,
        total,
        pageNumber,
        pageSize,
        pagination,
        run,
    };
}

export default usePagination;
