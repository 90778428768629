<template>
  <a-card title="备注" :bordered="false">
    <template #extra>
      <a-tooltip placement="topRight" :mouseEnterDelay="0.5">
        <template #title>新增备注</template>
        <a v-if="!isEdit" @click="add">新增</a>
      </a-tooltip>
      <div v-if="isEdit">
        <a @click="save">保存</a>
        <a-divider type="vertical"/>
        <a @click="cancel">取消</a>
      </div>
    </template>
    <div class="card-content">
      <div v-if="isEdit" class="edit-wrap">
        <a-textarea v-model:value="newNote" showCount :maxlength="500" :rows="4" />
      </div>
      <div class="item" v-for="item in localNotes" :key="item.id">
        <label class="note">{{ item.note }}</label>
        <span class="time">{{ item.createTime }}</span>
        <a-divider/>
      </div>
      <ak-empty v-if="localNotes.length === 0 && !isEdit" />
    </div>
  </a-card>
</template>

<script>
import request from "@/utils/request";
import {defineComponent, ref, toRefs, watch, inject} from "vue";
import {apiMap} from "@/utils/uriMap";
import {notification} from 'ant-design-vue';
import AkEmpty from "@/components/akEmpty";

export default defineComponent({
  name: "noteCard",
  components: {
    AkEmpty,
  },
  props: {
    userNotes: {
      type: Object,
      default: Array,
    },
    user: {
      type: Object,
      default: null,
    }
  },
  setup(props) {
    const {userNotes, user} = toRefs(props);
    const isEdit = ref(false);
    const localNotes = ref([]);
    const newNote = ref('');
    const isDrawerOpen = inject('isDrawerOpen');

    const add = () => {
      isEdit.value = true;
    }

    const cancel = () => {
      isEdit.value = false;
      newNote.value = '';
    }

    const save = () => {
      request.post(apiMap.addUserNote, {userId: user.value.id, note: newNote.value})
      .then(res => {
        switch (res.status) {
          case 201:
            localNotes.value.unshift(res.data.data.note);
            notification.success({message: '保存成功！'});
            cancel();
            break;
          default:
            console.log(res);
            notification.error({message: '未能保存备注'});
            break;
        }
      })
      .catch(err => {
        console.log(err);
        notification.error({message: '未能保存备注'});
      })
    }

    watch(userNotes, () => {
      localNotes.value = userNotes.value;
    })

    watch(isDrawerOpen, () => {
      !isDrawerOpen.value && cancel();
    })

    return {
      isEdit,
      newNote,
      localNotes,
      add,
      cancel,
      save,
    }
  }
})
</script>

<style lang="less" scoped>
.card-content {
  display: flex;
  flex-direction: column;
}

.item {
  display: flex;
  flex-direction: column;
  width: 100%;

  .note {
    white-space: pre-line;
  }

  .time {
    margin-top: 8px;
    color: #757575;
  }
}

.edit-wrap {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  background: #f2f2f2;
  padding: 12px 8px 8px;
}
</style>