<template>
  <a-card v-if="user" title="基本信息" :bordered="false">
    <template #extra>
      <a-tooltip v-if="!isEdit" placement="topRight" :mouseEnterDelay="0.5">
        <template #title>切换到编辑状态</template>
        <a @click="() => toggleEditMode(true)">编辑</a>
      </a-tooltip>
      <div v-else>
        <a @click="saveUser">保存</a>
        <a-divider type="vertical"/>
        <a @click="cancelEdit">取消</a>
      </div>
    </template>
    <div class="card-content">
      <div class="item">
        <label class="title">姓名</label>
        <span class="content" v-if="!isEdit">{{ editUser.familyName + editUser.givenName }}</span>
        <a-input-group v-else compact class="content">
          <a-input style="width: 40%" v-model:value="editUser.familyName" placeholder="姓"/>
          <a-input style="width: 60%" v-model:value="editUser.givenName" placeholder="名"/>
        </a-input-group>
      </div>
      <div class="item">
        <label class="title">性别</label>
        <span class="content" v-if="!isEdit">{{ userSexName }}</span>
        <a-select v-else
                  class="content"
                  v-model:value="editUser.sex"
                  placeholder="选择性别"
        >
          <a-select-option v-for="item in sexOptions"
                           :key="item.id"
                           :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
      <div class="item">
        <label class="title">所在城市</label>
        <span class="content" v-if="!isEdit">{{ editUser.city }}</span>
        <a-input class="content" v-else v-model:value="editUser.city" placeholder="所在城市"/>
      </div>
      <div class="item">
        <label class="title">其他</label>
        <span class="content" v-if="!isEdit">{{ editUser.other }}</span>
        <a-input class="content" v-else v-model:value="editUser.other" placeholder="其他"/>
      </div>
      <div class="item">
        <label class="title">手机号</label>
        <span class="content">{{ editUser.mobile }}</span>
      </div>
      <div class="item">
        <label class="title">分配销售</label>
        <span class="content" v-if="!isEdit">{{
            editUser.salesmanName ? editUser.salesmanName : nullOption.name
          }}</span>
        <a-select v-else
                  class="content"
                  v-model:value="editUser.salesmanId"
                  placeholder="选择销售"
        >
          <a-select-option v-for="item in salesmanList"
                           :key="item.id"
                           :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
    </div>
  </a-card>

</template>

<script>
import request from "@/utils/request";
import {apiMap} from "@/utils/uriMap";
import {defineComponent, ref, toRefs, computed, onMounted, watch, inject} from "vue";
import {notification} from 'ant-design-vue';

const nullOption = {id: '0', name: '无'}

export default defineComponent({
  name: "userCard",
  props: {
    user: {
      type: Object,
      default: null,
    }
  },
  setup(props) {
    const {user} = toRefs(props);
    const sexOptions = ref([{id: 0, name: '无'}, {id: 10, name: '先生'}, {id: 20, name: '女士'}]);
    const isEdit = ref(false);
    const editUser = ref();
    const salesmanList = ref([nullOption]);
    const searchTrigger = inject('searchTrigger');
    const isDrawerOpen = inject('isDrawerOpen');

    // 将性别值转为名
    const userSexName = computed(() => {
      const findSex = editUser.value && sexOptions.value.find(x => x.id === editUser.value.sex);
      let name = findSex ? findSex.name : '-';

      return name;
    });

    onMounted(() => {
      getSalesmen();
    });

    // 获取销售列表
    const getSalesmen = () => {
      request.post(apiMap.searchSalesmen, {needCount: false})
          .then(res => {
            switch (res.status) {
              case 200:
                salesmanList.value = [nullOption, ...res.data.data.salesmen];
                break;
              default:
                break;
            }
          })
          .catch(err => {
            console.log(err);
          })
    };

    watch(user, () => {
      editUser.value = {...user.value};
    })

    // 切换编辑模式
    const toggleEditMode = (state) => {
      isEdit.value = state;
    };

    // 取消编辑
    const cancelEdit = () => {
      editUser.value = {...user.value};
      toggleEditMode(false);
    }

    // 更新用户基本信息
    const saveUser = () => {
      editUser.value.name = editUser.value.familyName + editUser.value.givenName;
      request.put(apiMap.updateUser, editUser.value)
          .then(res => {
            switch (res.status) {
              case 200: {
                notification.success({message: '保存成功！'});
                searchTrigger();
                toggleEditMode(false);
                break;
              }
              default: {
                console.log(res);
                notification.error({message: '未能保存用户信息'});
                break;
              }
            }
          })
          .catch(err => {
            console.log(err);
            notification.error({message: '未能保存用户信息'});
          })
    }

    watch(isDrawerOpen, () => {
      !isDrawerOpen.value && cancelEdit();
    })

    return {
      isEdit,
      editUser,
      userSexName,
      sexOptions,
      salesmanList,
      toggleEditMode,
      cancelEdit,
      saveUser,
      nullOption,
    }
  }
})
</script>

<style lang="less" scoped>
.card-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.item {
  display: flex;
  align-items: center;
  width: 48%;
  margin-bottom: 8px;

  .title {
    width: 68px;
    color: #757575;

    &:after {
      content: ':';
    }
  }

  .content {
    flex: 1;
  }
}
</style>
