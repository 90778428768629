<template>
  <a-drawer
            title="详细信息"
            width="520"
            placement="right"
            :closable="false"
            :visible="visible"
            @close="onClose"
            :drawerStyle="{background: '#f3f3f3'}"
  >
    <user-card class="card" :user="user"/>
    <sms-card class="card" :userSmsList="userSmsList"/>
    <note-card class="card" :userNotes="userNotes" :user="user"/>
  </a-drawer>
</template>

<script>
import request from "@/utils/request";
import {defineComponent, ref, toRefs, watch, provide} from "vue";
import {apiMap} from "@/utils/uriMap";
import {notification} from 'ant-design-vue';
import UserCard from "@/views/user/userCard";
import SmsCard from "@/views/user/smsCard";
import NoteCard from "@/views/user/noteCard";

export default defineComponent({
  name: "detailDrawer",
  components: {
    SmsCard,
    UserCard,
    NoteCard,
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    userId: {
      type: String,
      default: '',
    }
  },
  setup(props, context) {
    const {visible, userId} = toRefs(props);
    const user = ref();
    const userSmsList = ref();
    const userNotes = ref();

    watch(visible, () => {
      visible.value && getUserDetail();
    })

    const getUserDetail = () => {
      request.get(apiMap.getUserDetail.format({userId: userId.value}))
          .then(res => {
            switch (res.status) {
              case 200: {
                const detail = res.data.data.detail;
                user.value = detail.user;
                userSmsList.value = detail.userSmsList;
                userNotes.value = detail.userNotes;
                break;
              }
              default: {
                notification.error({message: '未能获取用户详情'});
                break;
              }
            }
          })
          .catch(err => {
            console.log(err);
            notification.error({message: '未能获取用户详情'});
          })
    };

    const onClose = () => {
      context.emit('update:visible', false);
    };

    provide('isDrawerOpen', visible);

    return {
      user,
      userSmsList,
      userNotes,
      onClose,
    }
  }
})
</script>

<style lang="less" scoped>
.card {
  margin-bottom: 24px;
}
</style>