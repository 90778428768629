<template>
  <a-modal title="发送短信"
           :visible="visible"
           :confirm-loading="confirmLoading"
           @cancel="onCancel"
           @ok="onOk"
           ok-text="发送"
           cancel-text="取消"
  >
    <div class="sms-info">
      <div class="sms-select">
        <label>短信模板</label>
        <a-select
            v-model:value="selectedSmsTemplateId"
            show-search
            placeholder="选择短信模板"
            style="width: 200px"
            :filter-option="filterOption"
            @change="handleChange"
        >
          <a-select-option v-for="template in smsTemplates"
                           :key="template.id"
                           :value="template.id"
          >
            {{ template.name }}
          </a-select-option>
        </a-select>
      </div>
      <div class="sms-count">
        <label>发送人数</label>
        <span class="count">{{ users.length }}</span>
      </div>
    </div>

    <div class="sms-preview">
      <div v-if="selectedSmsTemplate">
        {{ "【" + selectedSmsTemplate.signature + "】" + selectedSmsTemplate.content }}
      </div>
      <div v-else class="tip">
        选择模板后，显示短信内容预览
      </div>
    </div>
  </a-modal>
</template>

<script>
import request from "@/utils/request";
import {apiMap} from "@/utils/uriMap";
import {defineComponent, ref, toRefs, watch} from "vue";
import {notification} from 'ant-design-vue';

export default defineComponent({
  name: "smsModal",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    users: {
      type: Array,
      default: ref([]),
    }
  },
  setup(props, context) {
    const {visible, users} = toRefs(props);
    const confirmLoading = ref(false);
    const smsTemplates = ref([]);
    const selectedSmsTemplateId = ref();
    const selectedSmsTemplate = ref();

    watch(visible, () => {
      visible.value && getSmsTemplates();
    })

    const getSmsTemplates = () => {
      request.get(apiMap.getSmsTemplates)
          .then(res => {
            switch (res.status) {
              case 200:
                smsTemplates.value = res.data.data.templates;
                break;
              default:
                notification.error({message: '未能获取短信模板'});
                break;
            }
          })
          .catch(err => {
            console.log(err);
            notification.error({message: '未能获取短信模板'});
          })
    }

    const handleChange = (_, option) => {
      selectedSmsTemplate.value = smsTemplates.value.find(x => x.id === option.key);
    };

    const filterOption = (input, option) => {
      return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    const onOk = () => {
      // 显示等待
      confirmLoading.value = true;

      const userIds = [];
      const smsTemplateId = selectedSmsTemplateId.value;

      users.value.forEach(x => {
        userIds.push(x.id);
      })

      request.post(apiMap.sendSms, {userIds, smsTemplateId})
          .then(res => {
            switch (res.status) {
              case 200: {
                notification.success({message: '短信发送成功！'});
                onCancel();
                break;
              }
              default: {
                console.log(res);
                notification.error({message: '未能发送短信'});
                break;
              }
            }
          })
          .catch(err => {
            console.log(err);
            notification.error({message: '未能发送短信'});
          })
          .finally(() => {
            confirmLoading.value = false;
          })
    }

    const onCancel = () => {
      selectedSmsTemplateId.value = undefined;
      selectedSmsTemplate.value = undefined;
      context.emit('update:visible', false);
    }

    return {
      value: ref(undefined),
      filterOption,
      handleChange,
      confirmLoading,
      smsTemplates,
      selectedSmsTemplateId,
      selectedSmsTemplate,
      onOk,
      onCancel,
    }
  }
})
</script>

<style lang="less" scoped>
.sms-info {
  display: flex;
  justify-content: space-between;
}

.sms-select, .sms-count {
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 4px;
    color: #757575;
  }
}

.count {
  font-size: 20px;

  &:after {
    content: "人";
    font-size: 10px;
    color: #757575;
    margin-left: 2px;
  }
}

.sms-preview {
  margin-top: 24px;
  padding: 16px 20px;
  background: #FFF9E9;
  border-radius: 4px;

  .tip {
    color: #757575;
    text-align: center;
  }
}
</style>
