<template>
  <!-- 页头 -->
  <a-page-header class="header" :title="title">
    <!-- 操作按钮 -->
    <template #extra>
      <a-button @click="onDownloadTemplate">下载模板</a-button>

      <!-- 导入 -->
      <Upload :before-upload="onFileChange"
              accept="xlsx"
              :multiple="false"
              :showUploadList="false"
      >
        <a-button>
          导入用户信息
        </a-button>
      </Upload>

      <!-- 导出 -->
      <a-button @click="onExportData" :disabled="isExporting">导出 Excel</a-button>

      <a-button @click="showSmsModal" :disabled="!enableSmsButton">发送短信</a-button>
    </template>
    <a-divider/>

    <!-- 搜索条件 -->
    <div class="search-con">
      <div class="field-wrap">
        <label class="label">姓名/手机</label>
        <a-input placeholder="姓名/手机" :allowClear="true" v-model:value="searchParams.userName"
                 @change="onUserNameChange"
                 @pressEnter="doSearch"/>
      </div>
      <div class="field-wrap">
        <label class="label">回复内容</label>
        <a-input placeholder="回复内容" :allowClear="true" v-model:value="searchParams.replyInfo"
                 @change="onReplyInfoChange"
                 @pressEnter="doSearch"/>
      </div>
      <div class="field-wrap">
        <label class="label">手机状态</label>
        <a-radio-group v-model:value="searchParams.mobileState">
          <a-radio-button :value=0>全部</a-radio-button>
          <a-radio-button :value=10>未知</a-radio-button>
          <a-radio-button :value=20>正常</a-radio-button>
          <a-radio-button :value=30>无效</a-radio-button>
        </a-radio-group>
      </div>
      <div class="field-wrap">
        <label class="label">发送类型</label>
        <a-radio-group v-model:value="searchParams.sendState">
          <a-radio-button :value=0>全部</a-radio-button>
          <a-radio-button :value=10>未发送</a-radio-button>
          <a-radio-button :value=20>已发送</a-radio-button>
        </a-radio-group>
      </div>
      <div class="field-wrap">
        <label class="label">回复类型</label>
        <a-radio-group v-model:value="searchParams.replyState">
          <a-radio-button :value=0>全部</a-radio-button>
          <a-radio-button :value=10>未回复</a-radio-button>
          <a-radio-button :value=20>已回复</a-radio-button>
        </a-radio-group>
      </div>
      <div class="field-wrap">
        <label class="label">发送日期</label>
        <a-range-picker class="date-picker" v-model:value="sendDateRange" :ranges="dateRanges"/>
      </div>
      <div class="field-wrap">
        <label class="label">回复日期</label>
        <a-range-picker class="date-picker" v-model:value="replyDateRange" :ranges="dateRanges"/>
      </div>
      <div class="field-wrap">
        <label class="label">所在城市</label>
        <a-input placeholder="所在城市" :allowClear="true" v-model:value="searchParams.city"
                 @pressEnter="doSearch"/>
      </div>
      <div class="field-wrap">
        <label class="label">其他</label>
        <a-input placeholder="其他" :allowClear="true" v-model:value="searchParams.other"
                 @pressEnter="doSearch"/>
      </div>
      <div class="search-btn-wrap">
        <a-button type="primary" @click="doSearch">
          <template #icon>
            <SearchOutlined/>
          </template>
          搜索
        </a-button>
        <a-button type="link" @click="resetSearch">重置</a-button>
      </div>
    </div>
  </a-page-header>

  <!-- 表格 -->
  <a-spin :spinning="isLoading">
    <a-table class="table"
             :dataSource="data"
             :columns="columns"
             rowKey="id"
             :row-selection="rowSelection"
             :pagination="pagination"
             :scroll="{x: true}"
             @change="onTableChange"
    >
      <template #title>
        <div class="table-title">
          <label>{{ pagination.showTotal(pagination.total) }}</label>
          <div>
            <a-tag class="new-tag-row">短信剩余：{{ smsBalance }} 条</a-tag>
            <a-checkable-tag :class="{'new-tag': true, checked: searchParams.replyReadState === 10}"
                             v-if="hasNewReply"
                             :checked="searchParams.replyReadState === 10"
                             color="red"
                             @change="onNewTagClick"
            >
              有新的用户回复
            </a-checkable-tag>
          </div>
        </div>
      </template>
      <template #name="{ text, record }">
        <a @click="() => showDetail(record)">{{ text }}</a>
      </template>
      <template #sex="{ record }">
        <label>{{ record.sex === 10 ? '先生' : record.sex === 20 ? '女士' : '' }}</label>
      </template>
      <template #mobileState="{ record }">
        <a-tag class="new-tag-row" v-if="record.mobileState === 10" color="default">未知</a-tag>
        <a-tag class="new-tag-row" v-if="record.mobileState === 20" color="green">正常</a-tag>
        <a-tag class="new-tag-row" v-if="record.mobileState === 30" color="red">
          无效{{ record.mobileErrMsg ? ', ' + record.mobileErrMsg : '' }}
        </a-tag>
      </template>
      <template #replyInfo="{ text, record }">
        <label>{{ text }}</label>
        <a-tag class="new-tag-row" v-if="record.replyReadState === 10" color="red">新</a-tag>
      </template>
      <template #action="{record}">
      <span>
        <a @click="() => showDetail(record)">详情</a>
      </span>
      </template>
    </a-table>
  </a-spin>

  <sms-modal v-model:visible="isShowSmsModal" :users="selectedUsers"/>
  <detail-drawer v-model:visible="isShowDetail" :userId="userId"/>
</template>

<script>
import request from "@/utils/request";
import {useRouter} from "vue-router";
import {defineComponent, reactive, ref, onMounted, computed, provide} from "vue";
import {SearchOutlined} from '@ant-design/icons-vue';
import {apiMap} from "@/utils/uriMap";
import {notification} from 'ant-design-vue';
import smsModal from "@/views/user/smsModal";
import detailDrawer from "@/views/user/detailDrawer";
import {Upload} from 'ant-design-vue'
import usePagination from "@/hook/usePagination";
import moment from "moment";

export default defineComponent({
  name: "userManager",
  components: {
    smsModal,
    detailDrawer,
    SearchOutlined,
    Upload,
  },
  setup() {
    const router = useRouter();
    const title = router.currentRoute.value.name;
    const searchParams = reactive({
      userName: '',
      userMobile: '',
      mobileState: 0,
      sendState: 0,
      replyState: 0,
      replyReadState: 0,
      replyInfo: '',
      sendTimeStart: '',
      sendTimeEnd: '',
      replyTimeStart: '',
      replyTimeEnd: '',
      needCount: true,
    });
    const sendDateRange = ref([]);
    const replyDateRange = ref([]);
    const dateRanges = {
      '今日': [moment(), moment()],
      '7日': [moment(), moment().add(7, 'd')],
      '本月': [moment(), moment().endOf('month')],
    };
    const columns = [
      {title: '姓名', dataIndex: 'name', width: 180, slots: {customRender: 'name'}, fixed: 'left'},
      {title: '性别', dataIndex: 'sex', slots: {customRender: 'sex'}},
      {title: '所在城市', dataIndex: 'city'},
      {title: '其他', dataIndex: 'other'},
      {title: '手机', dataIndex: 'mobile', width: 120},
      {title: '手机状态', dataIndex: 'mobileState', width: 120, slots: {customRender: 'mobileState'}},
      {title: '已发短信', dataIndex: 'smsTemplateName'},
      {title: '发送时间', dataIndex: 'sendTime'},
      {title: '用户回复', dataIndex: 'replyInfo', slots: {customRender: 'replyInfo'}},
      {title: '回复时间', dataIndex: 'replyTime'},
      {title: '备注', dataIndex: 'note', width: 300},
      {title: '分配销售', dataIndex: 'salesmanName'},
      {title: '操作', key: 'action', slots: {customRender: 'action'}},
    ];
    const isShowSmsModal = ref(false);
    const isShowDetail = ref(false);
    const isLoading = ref(false);
    const selectedUserIds = ref([]);
    const selectedUsers = ref([]);
    const userId = ref('');
    const fileList = ref([]);
    const importResultEnum = {
      10: '成功',
      20: '未能匹配导入的销售',
      30: '导入数据中存在重复的手机号',
      40: '文件中缺少必要字段，请使用导入模板填写',
      50: '部分字段格式不正确，请检查是否为空或填写内容错误',
      60: '部分数值长度超出限制，请检查填写是否正确',
      70: '模板中无数据',
      80: '数据量超出了单次导入的上限',
      90: '导入的手机号已存在',
    }
    const {data, pageNumber, pageSize, pagination, run} = usePagination();
    const hasNewReply = ref(false);
    const smsBalance = ref(0);
    const isExporting = ref(false);
    const maxExportRows = 10000;

    // 挂载
    onMounted(() => {
      doSearch();
    });

    // 重置搜索条件
    const resetSearch = () => {
      searchParams.userName = '';
      searchParams.userMobile = '';
      searchParams.mobileState = 0;
      searchParams.sendState = 0;
      searchParams.replyState = 0;
      searchParams.replyReadState = 0;
      searchParams.sendTimeStart = '';
      searchParams.sendTimeEnd = '';
      searchParams.replyTimeStart = '';
      searchParams.replyTimeEnd = '';
      searchParams.replyInfo = '';
      searchParams.city = '';
      searchParams.other = '';
      pageNumber.value = 0;
      sendDateRange.value = [];
      replyDateRange.value = [];

      doSearch();
    }

    // 搜索用户函数
    const searchUsers = (params) => {
      isLoading.value = true;
      return request.post(apiMap.searchPromoUsers, params)
          .then(res => {
            isLoading.value = false;
            switch (res.status) {
              case 200: {
                const {users, count} = res.data.data;
                smsBalance.value = res.data.data.smsBalance;
                return Promise.resolve({data: users, total: count});
              }
              default: {
                notification.error({message: '未能完成数据搜索'});
                return Promise.reject();
              }
            }
          })
          .catch(err => {
            isLoading.value = false;
            notification.error({message: '未能完成数据搜索'});
            console.log(err);
            return Promise.reject();
          })
    }

    // 执行搜索
    const doSearch = () => {
      selectedUserIds.value = [];
      selectedUsers.value = [];

      searchParams.sendTimeStart = '';
      searchParams.sendTimeEnd = '';
      searchParams.replyTimeStart = '';
      searchParams.replyTimeEnd = '';

      // 处理日期参数
      if (sendDateRange.value.length > 0) {
        searchParams.sendTimeStart = sendDateRange.value[0].format('YYYY-MM-DD');
        searchParams.sendTimeEnd = sendDateRange.value[1].format('YYYY-MM-DD');
      }

      if (replyDateRange.value.length > 0) {
        searchParams.replyTimeStart = replyDateRange.value[0].format('YYYY-MM-DD');
        searchParams.replyTimeEnd = replyDateRange.value[1].format('YYYY-MM-DD');
      }

      // 搜索用户
      run({
        queryFunc: searchUsers,
        pageNumber: pageNumber.value,
        pageSize: pageSize.value,
        ...searchParams
      });

      //检查是否存在未读回复
      checkUnreadReply();
    }

    // 表格翻页、pageSize 改变
    const onTableChange = (pag) => {
      pageSize.value = pag.pageSize;
      pageNumber.value = pag.current;

      doSearch();
    };

    // 搜索条件用户名修改
    const onUserNameChange = (e) => {
      searchParams.userMobile = e.currentTarget.value;
    };

    const onReplyInfoChange = (e) => {
      searchParams.replyInfo = e.currentTarget.value;
    }

    //检查是否存在未读回复
    const checkUnreadReply = () => {
      request.get(apiMap.checkUnreadReply)
          .then(res => {
            if (res.status === 200) {
              hasNewReply.value = res.data.data.exist;
            }
          })
    }

    // 搜索新用户回复
    const onNewTagClick = checked => {
      searchParams.replyReadState = checked ? 10 : 0;
      pageNumber.value = 0;
      doSearch();
    }

    // 选中用户
    const rowSelection = {
      selectedRowKeys: selectedUserIds,
      onChange: (selectedRowKeys, selectedRows) => {
        console.log(selectedRowKeys)
        selectedUserIds.value = selectedRowKeys;
        selectedUsers.value = selectedRows;
      },
    };

    // 发送短信按钮是否可用
    const enableSmsButton = computed(() => {
      return selectedUsers.value.length > 0
    })

    // 发送短信
    const showSmsModal = () => {
      isShowSmsModal.value = true;
    }

    // 查看详情
    const showDetail = (user) => {
      userId.value = user.id;
      isShowDetail.value = true;

      if (user.replyReadState === 10) {
        updateReplyReadState(user.id);
      }
    }

    // 更新已读状态
    const updateReplyReadState = (userId) => {
      request.put(apiMap.updateUserSms, {keyField: 'userId', userId: userId, replyReadState: 20})
          .then(res => {
            switch (res.status) {
              case 200:
                break;
              default:
                console.log(res);
                break;
            }
          })
          .catch(err => {
            console.log(err);
          })
    }

    // 下载模板
    const onDownloadTemplate = () => {
      window.location.href =
          'https://ankh-data.oss-cn-beijing.aliyuncs.com/template/%E7%9F%AD%E4%BF%A1%E7%94%A8%E6%88%B7%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx?versionId=CAEQLhiBgICtn9O.kBgiIDQwNTNhOGQ0MjdjODRiMjQ4MzAwNGE3YmNiMzZkNGQw';
      notification.success({message: '模板已下载，请查看下载文件'});
    }

    // 导入数据
    const onFileChange = file => {
      isLoading.value = true;
      notification.destroy()
      const form = new FormData();
      form.append('importFile', file);
      request.post(apiMap.importTemplate, form).then(res => {
        const {result} = res.data.data;
        switch (res.status) {
          case 201: {
            let desc = '';
            result.traces.forEach(x => {
              desc += "\r\n" + importResultEnum[x.result] + ` (${x.values.length}条)` + "\r\n" + (x.msg ? x.msg + '\r\n' : '') + (x.values ? x.values.join(", ") : '' ) + "\r\n";
            })

            const duration = desc === '' ? 2 : null;
            notification.success({
              message: `成功导入 ${result.insertedCount} 条！`,
              description: desc,
              duration: duration,
              class: 'user-notification',
            });
            doSearch();
            break;
          }
          default: {
            let desc = ''; //result.msg + "\r\n";
            result.traces.forEach(x => {
              desc += importResultEnum[x.result] + "\r\n" + (x.msg ? x.msg + '\r\n' : '') + '\r\n' + (x.values ? x.values.join(", ") : '' );
            })

            notification.error({
              message: '未能完成导入',
              description: desc,
              duration: null,
              class: 'user-notification',
            });
            break;
          }
        }
      })
          .finally(() => {
            isLoading.value = false;
          })

      // 返回 false，禁止上传组件自动上传
      return false;
    }

    // 导出数据
    const onExportData = () => {
      if (pagination.value.total > maxExportRows) {
        notification.error({message: `导出数据量过大，请缩小数据范围至 ${maxExportRows} 条以内`});
        return;
      }

      isExporting.value = true;
      request.post(apiMap.exportData, searchParams)
          .then(res => {
            switch (res.status) {
              case 200:
                switch (res.data.data.result) {
                  case 10:
                    window.location.href = res.data.data.fileSrc;
                    notification.success({message: '文件已下载'});
                    break;
                  case 20:
                    notification.error({message: `导出数据量过大，请缩小数据范围至 ${maxExportRows} 条以内`});
                    break;
                }
                break;
            }
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            isExporting.value = false;
          })
    }

    provide('searchTrigger', doSearch);

    return {
      title,
      searchParams,
      sendDateRange,
      replyDateRange,
      dateRanges,
      columns,
      data,
      rowSelection,
      selectedUserIds,
      selectedUsers,
      isShowSmsModal,
      isShowDetail,
      isLoading,
      enableSmsButton,
      userId,
      fileList,
      pagination,
      hasNewReply,
      smsBalance,
      isExporting,
      resetSearch,
      doSearch,
      showSmsModal,
      showDetail,
      onDownloadTemplate,
      onFileChange,
      onTableChange,
      onUserNameChange,
      onReplyInfoChange,
      onNewTagClick,
      onExportData,
    }
  }
})
</script>

<style lang="less" scoped>
.ant-divider-horizontal {
  margin: 4px 0 20px 0;
}

.header {
  position: sticky;
  top: 0;
  z-index: 5;
  margin: -24px;
  padding: 24px 24px 12px 24px !important;
}

.table-title {
  display: flex;
  justify-content: space-between;
}

.search-con {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;

  .field-wrap {
    flex-direction: column;
    margin-right: 24px;
    margin-bottom: 12px;

    .label {
      display: block;
      margin-bottom: 4px;
      color: #757575;
    }

    .date-picker {
      width: 220px;
    }
  }

  .search-btn-wrap {
    display: flex;
    margin-bottom: 12px;
  }
}

.table {
  margin-top: 32px;
}

.new-tag {
  cursor: pointer;
  color: #f5222d;
  border-color: #ffa39e;
  margin-left: 8px;

  &:hover {
    color: #f5222d;
  }

  &.checked {
    //color: #fff;
    background: #fff1f0;
  }
}

.new-tag-row {
  margin-left: 4px;
}

</style>

<style lang="less">
.user-notification {
  .ant-notification-notice-description {
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
    word-break: break-word;
    white-space: pre-line;
  }

}
</style>
